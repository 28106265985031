export enum ERROR_TYPE {
  'ALREADY_EXISTING_USER' = 'duplicate key value violates unique constraint "clients_email_key"',
}

export const SENDGRID_ACCEPTED_EMAIL_TEMPLATE_ID =
  'd-4a2e16ae48f64e798b976440dbd54bda';
export const SENDGRID_REGISTERED_EMAIL_TEMPLATE_ID =
  'd-a59c594563444dd9b691a086462c6004';

// RFC-5322 compliant Email Pattern
export const EMAIL_VALIDATOR =
  /^([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"\(\[]!#-[^\t ~-]|(\\[\t -~])+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])$/;

export enum CSV_HEADER {
  'DISCORD' = 'discord',
  'TELEGRAM' = 'telegram',
  'CREATED_AT' = 'date',
  'NAME' = 'name',
  'EMAIL' = 'email',
}

export const SUPER_ADMIN_ROLE = 'super-admin';
